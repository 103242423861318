(function($) {
    'use strict';

    var UICtrl = (function() {

        var UISelectors = {
            navicon             : $('.c-navicon'),
            naviconNav          : $('.c-navicon--nav'),
            primaryNav          : $('.c-nav-primary'),
            primaryNavBody      : $('body'),
            naviconInner        : $('.c-navicon__inner'),
            menuItem            : $('.menu__item'),
            subMenu             : $('.sub-menu'),
            parentNavItem       : $('.menu__item--has-children > a'),
            pageHeader          : $('.c-page-header'),
            headerCta           : $('.c-page-header__cta'),
            headerCtaText       : $('.c-page-header__cta p'),
            primaryNavInner     : $('.c-nav-primary__inner .menu'),
            primaryNavInnerLink : $('.c-nav-primary__inner .menu .menu__item a'),
            socialIcons         : $('.c-page-header__social-links svg'),
            socialIconsLink     : $('.c-page-header__social-links a'),
            mainContentColumn   : $('.s-main'),
            scrollBox           : $('.c-scroll-box'),
            pageFooter          : $('.c-page-footer'),
            sidebarActivator    : $('.c-button--sidebar-activator'),
            sidebar             : $('.c-sidebar'),
            offcanvasForm       : $('.c-table-booking--opentable'),
            sidebarButton       : $('.c-button--sidebar'),
            sidebarButtonRight  : $('.c-button--sidebar-right'),
            rowColumnSwitcher   : $('.c-row__column-switcher'),
            rowBgOverflow       : $('.c-row--background-primary-color--overflow'),
            rowBgOverflowNone   : $('.c-row--background-primary-color--overflow-none'),
            pageBody            : $('body')
        };

        var UIHelpers = {
            isActive        : 'is-active',
            isVisable       : 'is-visable',
            isOffset        : 'is-offset',
            isScrolling     : 'is-scrolling',
            primaryColor    : 'is-primary-color',
            parentPage      : 'body--page-parent',
            lockScroll      : 'lock-scroll',
            breakPoints     : [425, 768, 1024]
        };

        var UIStyles = {
            $tPrimaryColor      : '#1d1d1d',
            $tLightGrayColor    : '#C1C1C1',
            $tBlack             : '#000000',
            positionFixed       : 'fixed'
        };


        var bookingFormActivator = function() {

            UISelectors.sidebarActivator.on('click', function(e) {

                UISelectors.sidebar.toggleClass(UIHelpers.isActive);

                if( UISelectors.sidebar.hasClass(UIHelpers.isActive) ) {

                    UISelectors.sidebarActivator.html('close');

                } else {

                    UISelectors.sidebarActivator.html('Book now');
                }

                e.preventDefault();
            });

            UISelectors.sidebarButton.on('click', function(e) {

                UISelectors.offcanvasForm.removeClass(UIHelpers.isActive);

                UISelectors.sidebarButtonRight.removeClass(UIHelpers.isActive);

                UISelectors.sidebarButton.css({
                    'background' : UIStyles.$tPrimaryColor
                });


                e.preventDefault();
            });
        };

        var menuToggleActivator = function() {

            UISelectors.navicon.on('click', function(e) {

                UISelectors.primaryNav.toggleClass(UIHelpers.isActive);

                UISelectors.naviconInner.toggleClass(UIHelpers.isActive);

                UISelectors.navicon.addClass(UIHelpers.isActive);

                UISelectors.primaryNav.addClass('is-scrollable');

                UISelectors.primaryNavBody.toggleClass('navigation--is-active');

                UISelectors.menuItem.toggleClass(UIHelpers.isActive);

                UISelectors.subMenu.removeClass(UIHelpers.isVisable);

                UISelectors.naviconInner.removeClass(UIHelpers.primaryColor);

                UISelectors.pageBody.toggleClass(UIHelpers.lockScroll);

                UICtrl.addPrimaryNavModifiers();

                e.preventDefault();

            });

        };

        var scrollBarActivator = function() {

            UISelectors.mainContentColumn.before('<span class="c-scroll-box"></span>');

            if( $('body').find('.c-hero--set-height') ) {

                $('.c-scroll-box').css({
                    'top' : '670px',
                });
            }

            if( $('body').find('.c-slider__full-width') ) {

                $('.c-scroll-box').css({
                    'top' : '690px',
                });
            }

            if( $('body').hasClass('body--parent-pageid-6') ) {

                $('.c-scroll-box').css({
                    'top' : '680px',
                });
            }


            if( $('body').hasClass('body--parent-pageid-9') ) {

                $('.c-scroll-box').css({
                    'top' : '520px',
                });
            }

            if( $('body').hasClass('body--parent-pageid-12') ) {

                $('.c-scroll-box').css({
                    'top' : '520px',
                });
            }

            if( $('body').hasClass('body--parent-pageid-15') ) {

                $('.c-scroll-box').css({
                    'top' : '520px',
                });
            }

            if( $('body').hasClass('body--page-gallery') ) {

                $('.c-scroll-box').css({
                    'top' : '520px',
                });
            }

            if( $('body').hasClass('body--siteorigin-panels-home') ) {

                $('.c-scroll-box').css({
                    'bottom' : '60px',
                    'top' : 'unset'
                });
            }


            $(window).bind('scroll', function() {

                var scrollUISelectors = {
                    scrollBox   : $('.c-scroll-box'),
                    pageFooter  : $('.c-page-footer'),
                    pageHero    : $('.c-hero')
                };

                if( scrollUISelectors.scrollBox.offset().top + scrollUISelectors.scrollBox.height() >= scrollUISelectors.pageFooter.offset().top) {

                    scrollUISelectors.scrollBox.css('position', 'absolute');

                } else if ($(document).scrollTop() + window.innerHeight < scrollUISelectors.pageFooter.offset().top) {

                    scrollUISelectors.scrollBox.css('position', 'fixed');
                }

                if($(window).scrollTop() >= scrollUISelectors.pageHero.offset()) {

                    scrollUISelectors.scrollBox.css('z-index', '0');
                } else {

                    scrollUISelectors.scrollBox.css('z-index', '5');
                }
            });
        };

        var subMenuActivator = function() {

            UISelectors.parentNavItem.on('click', function(e) {

                UISelectors.primaryNav.removeClass('is-scrollable');

                UISelectors.subMenu.removeClass(UIHelpers.isVisable);

                $(this).parent().find(UISelectors.subMenu).addClass(UIHelpers.isVisable);

                if( $(window).width() < UIHelpers.breakPoints[0] ) {

                    if ( UISelectors.subMenu.hasClass(UIHelpers.isVisable) ) {

                        UISelectors.naviconInner.addClass(UIHelpers.primaryColor);
                    }
                }

                UICtrl.addSubMenuModifiers();

                // if( $(window).height() < 628 ) {
                //     UISelectors.headerCta.css({
                //         'margin-top': '-330px'
                //     });
                // }

              e.preventDefault();

            });

        };

        var subMenuDeactivate = function() {

            UISelectors.parentNavItem.on('click', function(e) {

                $(this).parent().find(UISelectors.subMenu).first('li a').on('click', function() {

                    $(UISelectors.subMenu).removeClass(UIHelpers.isVisable);

                    UICtrl.addPrimaryNavModifiers();

                    UISelectors.naviconInner.removeClass(UIHelpers.primaryColor);

                });

                e.preventDefault();
            });
        };

        var rowBackgroundActivator = function() {

            UISelectors.rowColumnSwitcher.wrap('<div class="c-row--background-primary-color-wrap"></div>');

            $('.c-row--background-brown-color-wrap').parent().removeClass('c-row--background-primary-color-wrap').addClass('c-row--background-brown-color-wrap-container');

            UISelectors.rowBgOverflow.wrap('<div class="c-row--background-primary-color-wrap c-row--background-primary-color-wrap--overflow"></div>');

            UISelectors.rowBgOverflowNone.wrap('<div class="c-row--background-primary-color-wrap c-row--background-primary-color-wrap--overflow c-row--background-primary-color-wrap--overflow-none"></div>');
        };


        return {
            initMenuToggle: function() {
                menuToggleActivator();
            },
            initSubmenu: function() {
                subMenuActivator();
            },
            initSubMenuDeactivate: function() {
                subMenuDeactivate();
            },
            initBookingForm: function() {
                bookingFormActivator();
            },
            addPrimaryNavModifiers: function() {
                if( $(window).width() < UIHelpers.breakPoints[0] ) {

                    UISelectors.headerCtaText.css({
                        'color': UIStyles.$tLightGrayColor
                    });

                    // UISelectors.primaryNavInner.css({
                    //     'background-color': UIStyles.$tPrimaryColor
                    // });

                    UISelectors.primaryNavInnerLink.css({
                        'color': UIStyles.$tLightGrayColor
                    });

                    UISelectors.socialIcons.css({
                        'fill': UIStyles.$tPrimaryColor,
                    });

                    UISelectors.socialIconsLink.css({
                        'background': UIStyles.$tLightGrayColor,
                        'border-radius': '50%',
                    });
                }
            },
            addSubMenuModifiers: function() {
                if( $(window).width() < UIHelpers.breakPoints[0] ) {

                    if( UISelectors.subMenu.hasClass(UIHelpers.isVisable)) {
                        UISelectors.headerCtaText.css({
                            'color': UIStyles.$tPrimaryColor
                        });

                        UISelectors.primaryNavInner.css({
                            'background-color': 'transparent'
                        });

                        UISelectors.primaryNavInnerLink.css({
                            'color': UIStyles.$tPrimaryColor
                        });

                        UISelectors.socialIcons.css({
                            'fill': UIStyles.$tLightGrayColor,
                        });

                        UISelectors.socialIconsLink.css({
                            'background': UIStyles.$tPrimaryColor,
                            'border-radius': '50%'
                        });
                    }
                }
            },
            initscrollBarActivator: function() {
                scrollBarActivator();
            },
            initRowBackgroundActivator: function() {
                rowBackgroundActivator();
            }
        };

    })();

    UICtrl.initMenuToggle();
    UICtrl.initSubmenu();
    UICtrl.initSubMenuDeactivate();
    UICtrl.initscrollBarActivator();
    UICtrl.initBookingForm();
    UICtrl.initRowBackgroundActivator();

    var $mpw_link = $('.c-cta--mpw .c-button').attr('href');

    $('.c-cta--mpw').append('<a href="' +$mpw_link+ '"><div class="c-badge"></div></a>');

    /**
     * Trigger booking URL on "#book" URL
     */
    $('a[href*="#book"]').on('click', function(e) {
        e.preventDefault();

        Booking.OpenSite('abbeyhotel', window.cnProfitroomOptions || {});
    });

    $(".c-page-footer__book").click(function(){
        $(this).toggleClass("is-active");
    });

    // jQuery(document).ready(function($) {
    //     var alterClass = function() {
    //     var ww = document.body.clientWidth;

    //     if (ww < 1000) {
    //         $('.c-sidebar').removeClass('is-active');
    //         $(".c-button--sidebar-activator").html("Book Now");
    //     } else if (ww >= 1001) {
    //             $('.c-sidebar').addClass('is-active');
    //             $(".c-button--sidebar-activator").html("close");
    //         };
    //     };

    //     $(window).resize(function(){
    //         alterClass();
    //     });

    //     alterClass();
    // });


})(jQuery);



